import React, { useCallback, useEffect, useState } from 'react'
import styles from './About.module.scss'
import { Container } from '../Container/Container'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'

import { Button } from '../Button/Button'
import { Title } from '../Title/Title'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'
import classNames from 'classnames'
import { useTransition, animated, easings, useSpringRef } from '@react-spring/web'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useControls } from 'leva'
import { FadeUp } from '../FadeUp/FadeUp'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { mediaQuery } from 'src/utils/mediaQueries'

let offset

export const About = () => {
  let isStarted = false

  const items = [
    {
      text: "Кейсы",
      href: "#cases"
    },
    {
      text: "Экспертиза",
      href: "#expertise"
    },
    {
      text: "Услуги",
      href: "#services"
    },
    {
      text: "Награды",
      href: "#awards"
    },
    {
      text: "Контакты",
      href: "#contacts"
    },
  ]
  const spans = ['E-commerce & Retail', 'Промышленности', 'FMCG & Finance', 'IT & Фарма']
  const [data, setData] = useState([])
  const transitions = useTransition(data, {
    from: {
      y: window.innerWidth > 767 ? 140 : 50,
      skewY: '6deg',
      scaleX: 0.9,
    },
    enter: {
      y: 0,
      skewY: '0deg',
      scaleX: 1,
    },
    leave: {
      y: window.innerWidth > 767 ? -145 : -50,
    },
    // config: config
    config: {
      tension: 110,
      friction: 56,
      precision: 0
    }
  })
  const changeData = useCallback(() => {
    setInterval(() => {
      const el = spans.shift()
      spans.push(el)
      setData([spans[0]])
    }, 2200)
  }, [])

  const dispatch = useDispatch()
  const { isLoaded } = useSelector(state => state.preloader)

  useEffect(() => {
    if (window.innerWidth < 1280) {
      offset = '25'
    } else {
      offset = '150'
    }

    
  }, [])

  useEffect(() => {
    if (!isStarted && isLoaded) {
      setTimeout(() => {
        setData([spans[0]])
        changeData()
      }, 1200);
      isStarted = true
    }
  }, [isLoaded])

  return (
    <div className={styles.section}>
      <Container>
        <div className={styles.row}>
          <div className={styles.col}>
            <FadeUp delay={800} isStart={isLoaded}>
              <div className={styles.logo}>
                <Logo />
              </div>
            </FadeUp>
          </div>
          <div className={styles.col}>
            <Title>
              {/* {isLoaded && */}
              
              <TextAnimation delay={600} isStart={isLoaded}>
                Дизайн и разработка <br />
              </TextAnimation>
              {/* } */}
              <span className={styles.wrapper}>
                {transitions((props, item, i) =>
                  <animated.div
                    style={props}
                    className={classNames(styles.span, i === 0 ? styles.static : null)}
                  >
                    для
                    <span className={styles.test}>
                      {item}
                    </span>
                  </animated.div>
                )}
                {/* {spans.map((item, i) => 
                  <span className={classNames(styles.span, i === 0 ? styles.static : null)} key={item}>{item}</span>
                )} */}
              </span>
            </ Title>
          </div>
          <div className={styles.col}>
            {/* {isLoaded && */}
            <FadeUp delay={800} isStart={isLoaded}>
              <div className={styles.btn}>
                <Button text='Обсудить проект' onClick={() => dispatch(openModal('project'))} />
              </div>
            </FadeUp>
            {/* } */}
          </div>
        </div>
        {isLoaded &&
        <ul className={styles.list}>
          {items.map(item =>
            <li className={styles.item} key={item.text}>
              <FadeUp delay={1400}>
                <AnchorLink offset={offset} href={item.href} className={styles.text}>
                  {item.text}
                </AnchorLink>
              </FadeUp>
            </li>
          )}
        </ul>
        }
      </Container>
    </div>
  )
}
