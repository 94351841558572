import React, { useEffect, useState } from 'react'
import { Title } from '../Title/Title'
import { File } from '../File/File'
import { Button } from '../Button/Button'
import { ReactComponent as Cross } from 'src/assets/images/cross.svg'
import { ReactComponent as Send } from 'src/assets/images/send.svg'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Modal.module.scss'
import { closeModal } from 'src/store/modals/actionCreators'
// import InputMask from "react-input-mask";
import InputMask from '@mona-health/react-input-mask'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { forms } from 'src/services/forms'

export const Modal = ({ type }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .email(),
    name: Yup.string().required(),
    phone: Yup
      .string()
      .required()
      .test(
        (value, ctx) => {
          const result = value.replaceAll(/\D/g, '')

          if (result.length !== 11) {
            return ctx.createError({message: 'Некорректный номер телефона', type: 'valid'})
          }
          return true
        }
      )
      ,
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, formState: { errors }, handleSubmit } = useForm(formOptions)

  const { currentModal } = useSelector(state => state.modals)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentModal === type) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [currentModal])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('body--hidden')
    } else {
      document.body.classList.remove('body--hidden')
    }
  }, [isOpen])

  const submit = async (data) => {
    console.log(data)

    const formData = new FormData()
    formData.append('type', type)
    for (let key in data) {
      if (key === 'email') {
        formData.append('mail', data[key])
      } else {
        formData.append(key, data[key]);
      }
    }
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('file[]', files[i])
      }
    }

    setIsLoading(true)
    try {
      const response = await forms(formData);
      console.log(response)
      setIsSent(true)
      setError('')
    } catch (e) {
      console.log(e)
      setError(e.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onChange = (e) => {
    const fileList = e.target.files
    const arr = []

    for (let file of fileList) {
      arr.push(file)
    }
    setFiles([...files, ...arr])
  }

  const deleteFile = (name) => {
    const arr = [...files].filter(item => item.name !== name)

    setFiles(arr)
  }

  return (
    <div
      className={classNames(styles.modal, isOpen ? styles.active : null)}
    >
      <div className={styles.overlay} onClick={() => dispatch(closeModal())}></div>
      <div className={classNames(styles.container, isSent ? styles.active : null)}>
        <div className={styles.wrapper}>
          <Title extraClass={styles.title}>
            {type === 'project' || type === 'bitrix'
              ?
              'Обсудить проект'
              :
              'Запросить Бенч'
            }
          </Title>
          <div className={styles.text}>
            {type === 'project'
              ?
              <>
                Расскажите нам о своем проекте,
                <br /> подумаем над ним вместе.
                <br /> Начните с простого <span className={styles.sep}>—</span> просто напишите нам.
              </>
              :
              type === 'bitrix'
                ?
                <>
                  Расскажите нам о своем проекте.
                  <br />
                  Мы свяжемся с вами, проведем брифинг и презентацию, где покажем, чем
                  Битрикс будет полезен именно вам!
                </>
                :
                <>
                  Усилим вашу команду нашими специалистами.
                  <br /> Пришлем CV в течение 2 часов.
                  <br /> Начните с простого <span className={styles.sep}>—</span> просто напишите нам.
                </>
            }
          </div>
          <form action="/" className={styles.form} onSubmit={handleSubmit(submit)}>
            <div className={styles.grid}>
              <div className={styles.item}>
                <input
                  className={styles.input}
                  type="text"
                  placeholder='Вас зовут'
                  {...register("name", { required: true })}
                  aria-invalid={errors.name ? "true" : "false"}
                />
                {errors.name?.type === 'required' && <div className={styles.error}>Обязательное поле</div>}
              </div>
              <div className={styles.item}>
                <InputMask
                  mask="+7 (999) 999-99-99"
                  className={styles.input}
                  placeholder='Телефон для связи'
                  {...register("phone", { 
                    // required: true, 
                  })}
                  aria-invalid={errors.phone ? "true" : "false"}
                />
                {/* <input 
                  className={styles.input} 
                  type="text" 
                  placeholder='Телефон для связи'
                  {...register("phone", { required: true })}
                  aria-invalid={errors.phone ? "true" : "false"}  
                /> */}
                {errors.phone?.type === 'required' && <div className={styles.error}>Обязательное поле</div>}
                {errors.phone?.type === 'valid' && <div className={styles.error}>{errors.phone.message}</div>}
              </div>
              <div className={styles.item}>
                <input
                  className={styles.input}
                  type="text"
                  placeholder='Почта'
                  {...register("email", { required: true })}
                  aria-invalid={errors.email ? "true" : "false"}
                />
                {errors.email?.type === 'required' && <div className={styles.error}>Обязательное поле</div>}
                {errors.email?.type === 'email' && <div className={styles.error}>Введено некорректное значение</div>}
              </div>
            </div>
            <textarea
              className={styles.textarea}
              placeholder={type === 'project' || type === 'bitrix' ? 'Немного о проекте' : 'Список специалистов'}
              {...register("text")}
            ></textarea>
            <div className={styles.inner}>
              <div className={styles.files}>
                {files.length
                  ?
                  <div className={styles.list}>
                    {files.map(item =>
                      <div className={styles.file} key={item.name}>
                        <div className={styles.name}>{item.name}</div>
                        <div
                          className={styles.delete}
                          onClick={() => deleteFile(item.name)}
                        >
                          <Cross />
                        </div>
                      </div>
                    )}
                  </div>
                  :
                  null
                }
                <div className={styles.btn}>
                  <File onChange={onChange} />
                </div>
              </div>
              <div className={classNames(styles.button, isLoading ? styles.loading : null)}>
                <div className={styles.layout}>
                  <div className={styles.loader}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <Button isBig={true} text='Отправить' icon={<Send />} />
              </div>
            </div>
            <div className={styles.policy}>
              Нажимая на кнопку «Отправить», вы даете согласие на <a className={styles.link} href="/privacy-policy.pdf" target="_blank" rel="noreferrer">Политику конфиденциальности</a>
            </div>
          </form>
          {error &&
            <div className={styles.error}>{error}</div>}
        </div>
        <div className={styles.response}>
          <Title extraClass={styles.title}>
            Отправлено
          </Title>
          <div className={styles.text}>Ваше сообщение уже у нас.
            <br /> Свяжемся с вами в ближайшее рабочее время.</div>
        </div>
        <div className={styles.icon} onClick={() => dispatch(closeModal())}>
          <Cross />
        </div>
      </div>
    </div>
  )
}
