import React, { useEffect, useRef, useState } from 'react'
import styles from './Chat.module.scss'
import { ReactComponent as Tg } from 'src/assets/images/tg-chat.svg'
import { ReactComponent as WhatsApp } from 'src/assets/images/wa-chat.svg'
import { ReactComponent as Cross } from 'src/assets/images/cross-chat.svg'
import classNames from 'classnames'
import { Picture } from '../Picture/Picture'
import InputMask from '@mona-health/react-input-mask'
import { FadeUp } from '../FadeUp/FadeUp'
import { Message } from './Message'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { forms } from 'src/services/forms'


const initialData = {
  developSite: [
    {
      type: 'appType',
      items: ["Корпоративный сайт", "Интернет-магазин", "Промо сайт", "Сложный сервис", "Другое",],
      message: {
        // isVideo: true,
        message: 'Супер!🎊\n У нас мощная и опытная команда менеджеров, дизайнеров и разработчиков. Каждый год наши проекты получают награды. К тому же, мы 22-е в рейтинге разработчиков и 8-е в рейтинге креативности.🏆',
        secondMessage: 'Подскажи, что именно необходимо? 👀',
        time: 4
      },
      progress: 65,
    },
    {
      type: 'description',
      message: {
        message: 'Опиши проект вкратце ✍️ '
      },
      progress: 77,
    },
    {
      type: 'site_price',
      message: {
        message: 'Какой бюджет выделен для проекта? 👀'
      },
      progress: 96,
    },
    {
      type: 'info',
      message: {
        message: 'И последнее — имя и номер телефона, чтобы мы могли связаться и уточнить все детали 📲'
      },
      progress: 100,
    },
    {
      type: 'finish',
      message: {
        // isVideo: true,
        message: 'Отлично! Спасибо 👐 Мы в ближайшее время ознакомимся с проектом и дадим обратную связь',
        secondMessage: 'А пока мы рассматриваем заявку, взгляни на наши последние кейсы 🤌',
        time: 2,
      }
    },
  ],
  developApp: [
    {
      type: 'appType',
      items: ["Кроссплатформа", "Нативное приложение", "Не имеет значения",],
      message: {
        // isVideo: true,
        message: 'Супер!🎊\n У нас мощная и опытная команда менеджеров, дизайнеров и разработчиков. Каждый год наши проекты получают награды. К тому же, мы 22-е в рейтинге разработчиков и 8-е в рейтинге креативности.🏆',
        secondMessage: 'Нужна кроссплатформа или нативное приложение?📱',
        time: 4
      },
      progress: 65,
    },
    {
      type: 'description',
      message: {
        message: 'Опиши проект вкратце ✍️ '
      },
      progress: 77,
    },
    {
      type: 'price',
      items: ["до 3 000 000 ₽", "свыше 3 000 000 ₽",],
      message: {
        message: 'Какой бюджет выделен для проекта? 👀'
      },
      progress: 96,
    },
    {
      type: 'info',
      message: {
        message: 'И последнее — имя и номер телефона, чтобы мы могли связаться и уточнить все детали 📲'
      },
      progress: 100,
    },
    {
      type: 'finish',
      message: {
        // isVideo: true,
        message: 'Отлично! Спасибо 👐 Мы в ближайшее время ознакомимся с проектом и дадим обратную связь',
        secondMessage: 'А пока мы рассматриваем заявку, взгляни на наши последние кейсы 🤌',
        time: 2,
      }
    },
  ],
  brand: [
    {
      type: 'description',
      message: {
        // isVideo: true,
        message: 'Супер!🎊\n У нас мощная и опытная команда менеджеров, дизайнеров и разработчиков. Каждый год наши проекты получают награды. К тому же, мы 22-е в рейтинге разработчиков и 8-е в рейтинге креативности.🏆',
        secondMessage: 'Опиши проект вкратце ✍️',
        time: 4
      },
      progress: 77,
    },
    {
      type: 'price',
      items: ["до 400 000 ₽", "свыше 400 000 ₽",],
      message: {
        message: 'Какой бюджет выделен для проекта? 👀'
      },
      progress: 96,
    },
    {
      type: 'info',
      message: {
        message: 'И последнее — имя и номер телефона, чтобы мы могли связаться и уточнить все детали 📲'
      },
      progress: 100,
    },
    {
      type: 'finish',
      message: {
        // isVideo: true,
        message: 'Отлично! Спасибо 👐 Мы в ближайшее время ознакомимся с проектом и дадим обратную связь',
        secondMessage: 'А пока мы рассматриваем заявку, взгляни на наши последние кейсы 🤌',
        time: 2,
      }
    },
  ],
  support: [
    {
      type: 'appType',
      items: ["Битрикс", "WordPress", "Laravel", "React.js", "Vue.js", "Node.js,", "TypeScript ", "Другое ",],
      message: {
        // isVideo: true,
        message: 'Супер!🎊\n У нас мощная и опытная команда менеджеров, дизайнеров и разработчиков. Каждый год наши проекты получают награды. К тому же, мы 22-е в рейтинге разработчиков и 8-е в рейтинге креативности.🏆',
        secondMessage: 'Подскажи, какой стек технологий? 🖥',
        time: 4
      },
      progress: 65,
    },
    {
      type: 'description',
      message: {
        message: 'Опиши вкратце, что нужно поддерживать ✍️'
      },
      progress: 77,
    },
    {
      type: 'price',
      items: ["до 50 000 ₽", "до 100 000 ₽", 'свыше 100 000 ₽'],
      message: {
        message: 'Какой ежемесячный бюджет поддержки? 👀'
      },
      progress: 96,
    },
    {
      type: 'info',
      message: {
        message: 'И последнее — имя и номер телефона, чтобы мы могли связаться и уточнить все детали 📲'
      },
      progress: 100,
    },
    {
      type: 'finish',
      message: {
        // isVideo: true,
        message: 'Отлично! Спасибо 👐 Мы в ближайшее время ознакомимся с проектом и дадим обратную связь',
        secondMessage: 'А пока мы рассматриваем заявку, взгляни на наши последние кейсы 🤌',
        time: 2,
      }
    },
  ],
}


const items = [
  {
    text: 'Сайт',
    key: 'developSite',
    progress: 48,
  },
  {
    text: 'Приложение',
    key: 'developApp',
    progress: 65,
  },
  {
    text: 'Брендинг',
    key: 'brand',
    progress: 65,
  },
  {
    text: 'Поддержка проекта',
    key: 'support',
    progress: 65,
  },
]

const welcome = [
  {
    text: 'Нет, все супер! Остаюсь',
    message: 'Отлично! Что тебя интересует? 🤗',
    progress: 30,
  },
  {
    text: 'Telegram',
    progress: 100,
    href: 'https://t.me/shaban_nadi',
  },
  {
    text: 'Whatsapp ',
    progress: 100,
    href: 'https://wa.me/79313167040'
  },
]

const sitePrices = {
  'Корпоративный сайт': ["до 800 000₽", "до 1 500 000₽", "до 3 000 000₽", "свыше 3 000 000₽",],
  'Интернет-магазин': ["до 2 000 000 ₽", "свыше 2 000 000 ₽",],
  'Промо сайт': ["до 1 000 000 ₽", "до 3 000 000₽", "свыше 3 000 000₽",],
  'Сложный сервис': ["до 3 500 000 ₽", "свыше 3 500 000 ₽",],
  'Другое': ["до 3 500 000 ₽", "свыше 3 500 000 ₽",],
}

export const Chat = () => {

  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState([
    {
      // isVideo: true,
      message: 'Привет!\n 👋Я Надя, аккаунт-менеджер Клаудмил.'
    },
  ])
  const [isLoading, setIsLoading] = useState(false)
  const [isStart, setIsStart] = useState(false)
  const [data, setData] = useState(null)
  const [step, setStep] = useState(null)
  const [result, setResult] = useState(null)
  const [currentMessage, setCurrentMessage] = useState('Привет!\n 👋Я Надя, аккаунт-менеджер Клаудмил.')
  const [progress, setProgress] = useState(16)

  const ref = useRef(null)

  const schema2 = Yup.object().shape({
    description: Yup.string().required(),
  });
  const schema = Yup.object().shape({
    name: Yup.string().required(),
    phone: Yup
      .string()
      .required()
      .test(
        (value, ctx) => {
          const result = value.replaceAll(/\D/g, '')

          if (result.length !== 11) {
            return ctx.createError({ message: 'Некорректный номер телефона', type: 'valid' })
          }
          return true
        }
      ),
  });
  const { register, formState: { errors }, handleSubmit } = useForm(
    { resolver: yupResolver(schema) }
  )
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2
  } = useForm(
    { resolver: yupResolver(schema2) }
  )

  const nextStep = (text, type, progress, message = text) => {
    const newMessage = data[step + 1].message

    setResult({
      ...result,
      [type]: text
    })
    setProgress(progress)
    setMessages([...messages, { isUser: true, message: message }])
    setCurrentMessage(newMessage.message)
    setIsLoading(true)
    setTimeout(() => {
      setStep(step + 1)
    }, 500);
    setTimeout(() => {
      if (!newMessage.secondMessage) {
        setIsLoading(false)
      }
      setMessages([...messages, { isUser: true, message: message }, newMessage])
    }, 1000);
    if (newMessage.secondMessage) {
      setTimeout(() => {
        setIsLoading(false)
        setCurrentMessage(newMessage.secondMessage)
        setMessages([...messages, { message: newMessage.secondMessage }])
      }, newMessage.time * 1000);
    }
  }

  const stepSubmit = (data) => {
    nextStep(data.description, 'description', 77)
  }

  const finishChat = async (formData) => {
    const { phone, name } = formData
    const newMessage = data[step + 1].message
    const message = {
      isUser: true,
      message: `Имя: ${name} \n Телефон: ${phone}`
    }
    const res = {
      ...result,
      phone: phone,
      name: name
    }
    setProgress(100)
    setMessages([...messages, message])
    setCurrentMessage(newMessage.message)
    setIsLoading(true)
    setTimeout(() => {
      setStep(step + 1)
    }, 500);
    setTimeout(() => {
      if (!newMessage.secondMessage) {
        setIsLoading(false)
      }
      setMessages([...messages, message, newMessage])
    }, 1000);
    if (newMessage.secondMessage) {
      setTimeout(() => {
        setIsLoading(false)
        setCurrentMessage(newMessage.secondMessage)
        setMessages([...messages, message, newMessage, { message: newMessage.secondMessage }])
      }, newMessage.time * 1000);
    }

    console.log("FINISH")
    console.log(res)
    try {
      const response = await forms(res)
      console.log(response)
    } catch (e) {
      console.log(e)
    }
  }

  const renderSwitch = (param) => {

    switch (param.type) {
      case 'description':
        return (
          <>
            <form className={styles.form} onSubmit={handleSubmit2(stepSubmit)}>
              <textarea
                className={styles.textarea}
                placeholder='О проекте'
                // value={desc}
                // onChange={e => setDesc(e.target.value)}
                {...register2("description", { required: true })}
                aria-invalid={errors2.description ? "true" : "false"}
              >
              </textarea>
              {errors2.description?.type === 'required' && <div className={styles.error}>Обязательное поле</div>}
              <div className={styles.buttons}>
                <button className={styles.button}>Отправить</button>
                <div
                  className={classNames(styles.button, styles.outline)}
                  onClick={() => nextStep('', param.type, param.progress, 'Пропустить')}
                >
                  Пропустить
                </div>
              </div>
            </form>
          </>
        )
      case 'info':
        return (
          <form className={styles.form} action="/" onSubmit={handleSubmit(finishChat)}>
            <div className={styles.offset}>
              <InputMask
                mask="+7 (999) 999-99-99"
                className={styles.input}
                placeholder='Телефон для связи'
                // value={phone}
                // onChange={e => setPhone(e.target.value)}
                {...register("phone", {
                  // required: true, 
                })}
                aria-invalid={errors.phone ? "true" : "false"}
              />
              {errors.phone?.type === 'required' && <div className={styles.error}>Обязательное поле</div>}
              {errors.phone?.type === 'valid' && <div className={styles.error}>{errors.phone.message}</div>}
            </div>
            <div className={styles.offset}>
              <input
                type="text"
                className={styles.input}
                placeholder='Имя и компания'
                // value={name}
                // onChange={e => setName(e.target.value)}
                {...register("name", { required: true })}
                aria-invalid={errors.name ? "true" : "false"}
              />
              {errors.name?.type === 'required' && <div className={styles.error}>Обязательное поле</div>}
            </div>
            <div className={styles.offset}>
              <button className={styles.button}>Отправить</button>
            </div>
          </form>
        )
      case 'site_price':
        return (
          <div className={styles.items}>
            {sitePrices[result.appType].map(item =>
              <div
                className={styles.item}
                key={item}
                onClick={() => nextStep(item, 'price', param.progress)}
              >
                {item}
              </div>
            )}
          </div>
        )
      case 'finish':
        return (
          <>
            <div className={styles.form}>
              <a className={styles.button} href="/projects">Перейти к кейсам</a>
            </div>
          </>
        )
      default:
        return (
          <>
            <div className={styles.items}>
              {param.items.map(item =>
                <div
                  className={styles.item}
                  key={item}
                  onClick={() => nextStep(item, param.type, param.progress)}
                >
                  {item}
                </div>
              )}
            </div>
          </>
        )
    }
  }

  const startForm = (key, text, progress) => {
    const steps = initialData[key]
    const newMessage = steps[0].message
    setMessages([...messages, { isUser: true, message: text }])
    setResult({
      type: 'chat_dev',
      service: text
    })
    setProgress(progress)
    setCurrentMessage(newMessage.message)
    setIsLoading(true)
    setTimeout(() => {
      setStep(0)
      setData(steps)
    }, 500);
    setTimeout(() => {
      if (!newMessage.secondMessage) {
        setIsLoading(false)
      }
      setMessages([...messages, { isUser: true, message: text }, newMessage])
    }, 500);
    if (newMessage.secondMessage) {
      setTimeout(() => {
        setIsLoading(false)
        setCurrentMessage(newMessage.secondMessage)
        setMessages([...messages, { isUser: true, message: text }, newMessage, { message: newMessage.secondMessage }])
      }, newMessage.time * 1000);
    }
  }

  const startChat = (item) => {
    setIsStart(true)
    setIsLoading(true)
    setMessages([...messages, { isUser: true, message: item.text }])
    setCurrentMessage(item.message)
    setProgress(item.progress)
    setTimeout(() => {
      setMessages([...messages, { isUser: true, message: item.text }, { message: item.message }])
      setIsLoading(false)
    }, 1000);
  }

  useEffect(() => {
    if (!isOpen) {
      document.body.classList.remove('body--hidden')
    } else {
      document.body.classList.add('body--hidden')
      if (messages.length < 2) {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
          setCurrentMessage('Если мало времени, мы можем продолжить общение в      Telegram или      WhatsApp.')
          setMessages([...messages, { message: 'Если мало времени, мы можем продолжить общение в      Telegram или      WhatsApp.' }])
        }, 2000);
      }
    }
  }, [isOpen])

  useEffect(() => {
    const chat = ref.current
    chat.scrollTop = chat.scrollHeight
  }, [messages])

  return (
    <>
      <div className={classNames(styles.chat, isOpen ? styles.open : null)}>
        <FadeUp>
          <div className={styles.avatar} onClick={() => setIsOpen(!isOpen)}>
            <div className={styles.img}>
              <div className={styles.hover}>
                Закрыть
              </div>
              <Picture fileName='ava-bg.jpg' />
              <div className={styles.pic}>
                <Picture fileName='avatar.jpg' />
              </div>
            </div>
            <div className={styles.icon}>
            </div>
          </div>
        </FadeUp>
      </div>
      <div className={classNames(styles.modal, isOpen ? styles.open : null)}>
        <div className={styles.overlay} onClick={() => setIsOpen(false)}></div>
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.image}>
                  <div className={styles.dot}></div>
                  <Picture fileName='avatar.jpg' />
                </div>
                <div className={styles.content}>
                  <div className={styles.name}>Надя Шабанова</div>
                  <div className={styles.post}>Аккаунт-менеджер </div>
                </div>
              </div>
              <div className={styles.col}>
                <a className={styles.btn} href='https://t.me/shaban_nadi' target='_blank'>
                  <Tg />
                </a>
                <a className={styles.btn} href='https://wa.me/79313167040' target='_blank'>
                  <WhatsApp />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.messages} ref={ref}>
              {messages.map(item =>
                <Message
                  isVideo={item.isVideo}
                  isUser={item.isUser}
                  message={item.message}
                  key={item.message}
                  currentMessage={currentMessage}
                />
              )}
            </div>
            <div className={classNames(styles.bot, isLoading ? styles.loading : null)}>
              <div className={styles.loader}>
                <div className={styles.bubble}>
                  <div className={styles.circle}></div>
                  <div className={styles.circle}></div>
                  <div className={styles.circle}></div>
                </div>
              </div>
              <div className={styles.wrapper}>
                {isStart ?
                  <>
                    {data ?
                      <>
                        {renderSwitch(data[step])}
                      </>
                      :
                      <div className={styles.items}>
                        {items.map(item =>
                          <div
                            className={styles.item}
                            key={item.key}
                            onClick={() => startForm(item.key, item.text, item.progress)}
                          >
                            {item.text}
                          </div>
                        )}
                      </div>
                    }
                  </>
                  :
                  <div className={styles.items}>
                    {welcome.map(item => {
                      return (item.message ?
                        <div className={styles.item} key={item.text} onClick={() => startChat(item)}>
                          {item.text}
                        </div>
                        :
                        <a className={styles.item} key={item.text} href={item.href} target='_blank'>
                          {item.text}
                        </a>
                      )
                    }
                    )}
                  </div>
                }
              </div>
              <div
                className={styles.progress}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
          <div className={styles.close} onClick={() => setIsOpen(false)}>
            <Cross />
          </div>
        </div>
      </div >
    </>
  )
}
