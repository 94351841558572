import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { motion } from "framer-motion";
import { BitrixContainer } from 'src/components/BitrixContainer/BitrixContainer'
import { ProjectsHeader } from 'src/components/Header/ProjectsHeader';
import { Footer } from 'src/components/Footer/Footer';
import { Modal } from 'src/components/Modal/Modal';
import { Chat } from 'src/components/Chat/Chat';

export const Bitrix = () => {

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])


  return (
    <>
      <motion.div
        className='page-overlay'
        initial={{ x: '0%', transition: { duration: 0.5 } }}
        animate={{ x: '100%', transition: { duration: 0.5 } }}
        exit={{ x: '100%', transition: { duration: 0.5 } }}
      />
      <motion.div
        className='page-overlay'
        initial={{ x: '-100%', transition: { duration: 0.5 } }}
        animate={{ x: '-100%', transition: { duration: 0.5 } }}
        exit={{ x: '0%', transition: { duration: 0.5 } }}
      />
      <ProjectsHeader modal={'bitrix'} />
      <BitrixContainer />
      <Footer />
      {/* <Chat /> */}
      <Modal type={'bitrix'} />
      <Modal type={'project'} />
    </>
  )
}
