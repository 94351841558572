import React, { useEffect, useRef, useState } from 'react'
import styles from './Filters.module.scss'
import { useNavigate } from 'react-router-dom'
import { FadeUp } from '../FadeUp/FadeUp'
import { useSelector } from 'react-redux'
import { FiltersItem } from './FiltersItem'

export const Filters = ({ selected }) => {

  const [data, setData] = useState([])
  const ref = useRef(null)

  const navigate = useNavigate()

  const { categories, totalCount } = useSelector(state => state.projects)

  useEffect(() => {
    const all = {
      text: 'Все кейсы',
      num: totalCount,
      translate: 'all',
    }

    setData([all, ...categories])
  }, [categories, totalCount])

  const onChange = (text) => {
    if (text !== selected) {
      if (text === 'all') {
        navigate('')
      } else {
        navigate(`?filter=${text}`)
      }
    }
  }

  return (
    <FadeUp>
      <div className={styles.list} ref={ref}>
        {data.map(item =>
          <FiltersItem 
            item={item} 
            key={item.text} 
            onChange={onChange}
            selected={selected}
            listRef={ref}
          />
        )}
      </div>
    </FadeUp>
  )
}
