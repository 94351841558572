import React, { useEffect, useState } from 'react'
import styles from './Cases.module.scss'
import { Section } from '../Section/Section'
import { Container } from '../Container/Container'
import { Category } from '../Category/Category'
import { Title } from '../Title/Title'
import axios from 'axios'
import { Project } from '../Project/Project'
import { Counter } from '../Counter/Counter'
import { ReactComponent as Arrow } from 'src/assets/images/arrow.svg'
import { ProjectsList } from '../ProjectsList/ProjectsList'
import { Link } from 'react-router-dom'
import { PROJECTS } from 'src/routes'
import classNames from 'classnames'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { FadeUp } from '../FadeUp/FadeUp'


const project = {
  "src": "utlc.webp",
  "href": "https://cases.cloudmill.ru/utlcera",
  "title": "ОТЛК ЕРА",
  "description": "Сайт интеграционного оператора железнодорожных транзитных сервисов между Китаем и Европой",
  "awards": [
    {
      "name": "Лучший сайт об услугах",
      "icon": "bronze"
    },
    {
      "name": "Адаптивный сайт",
      "icon": "bronze"
    },
    {
      "name": "Tagline Awards",
      "count": 3
    },
    {
      "name": "Рейтинг Рунета",
      "count": 1
    }
  ],
  "video": "utlc.mp4",
  "categories": ["Промышленность"]
}

export const Cases = () => {

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    setIsLoading(true)
    try {
      const response = await axios.get("projects.json")

      setData(response.data.main)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Section>
      <Container>
        <TextAnimation>
          <Title extraClass={styles.title}>
            Выступаем партнером роста
            <br /> и масштабирования бизнеса
            <br /> в цифровой среде
          </Title>
        </TextAnimation>
        <Section id="cases">
          <Category num='1' text='Кейсы' />
          <div className={styles.wrapper}>
            <TextAnimation>
              <div className={styles.heading}>
                Ориентированы на результат и без лишних слов доказываем это на наших проектах
              </div>
            </TextAnimation>
          </div>
          <div className={styles.inner}>
            <div className={styles.row}>
              <div className={styles.col}>
                <Counter num='360+' text='реализованных проектов' />
              </div>
              <div className={styles.col}>
                <div className={styles.card}>
                  <Project project={project} />
                </div>
              </div>
              <div className={styles.col}>
                <Counter num='15+' text='лет опыта' />
              </div>
            </div>
          </div>
          <div className={styles.inner}>
            <div className={classNames(styles.card, styles.mobile)}>
              <Project project={project} />
            </div>
            <ProjectsList data={data} />
          </div>
        </Section>
        <Section>
          <FadeUp>
            <Link to={PROJECTS}>
              <div className={styles.link}>
                <div className={styles.text}>Все проекты</div>
                <div className={styles.icon}>
                  <Arrow />
                </div>
              </div>
            </Link>
          </FadeUp>
        </Section>
      </Container>
    </Section>
  )
}
