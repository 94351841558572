const { SET_PROJECTS } = require("./actions")

const initialState = {
  categories: [],
  totalCount: 0,
}

const translate = {
  'Retail': 'retail',
  'E-commerce': 'commerce',
  'FMCG': 'fmcg',
  'Промышленность': 'industry',
  'Финансы': 'finance',
  'Телеком': 'tv',
  'Фарма': 'pharma',
  'IT': 'it',
}

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      let obj = {};
      let count = action.payload.length

      action.payload.forEach(item => {
        const categories = item.categories

        for (let i = 0; i < categories.length; i++) {
          if (obj[categories[i]]) {
            obj[categories[i]] = ++obj[categories[i]]
          } else {
            obj[categories[i]] = 1
          }
        }
      });

      const arr = []
      for (let key in obj) {
        arr.push({
          text: key,
          num: obj[key],
          translate: translate[key]
        })
      }
      arr.sort((a, b) => {
        return b.num - a.num
      })

      return { ...state, categories: arr, totalCount: count }
    default: 
      return state;
  }
}

export default projectsReducer