import { Bitrix } from "./pages/Bitrix"
import { Main } from "./pages/Main"
import { Projects } from "./pages/Projects"

export const MAIN = "/"
export const PROJECTS = "/projects"
export const BITRIX = "/bitrix24"

const routes = [
  {
    path: MAIN,
    element: <Main />
  },
  {
    path: PROJECTS,
    element: <Projects />
  },
  {
    path: PROJECTS + "/",
    element: <Projects />
  },
  {
    path: BITRIX,
    element: <Bitrix />
  },
  {
    path: BITRIX + "/",
    element: <Bitrix />
  },
]

export {routes}