import React from 'react'
import styles from './Section.module.scss'

export const Section = ({ children, id }) => {
  return (
    <div 
      className={styles.section} 
      id={id}
    >
      {children}
    </div>
  )
}
