import React from 'react'
import styles from './ProjectsList.module.scss'
import { Project } from '../Project/Project'

export const ProjectsList = ({ data }) => {
  return (
    <div className={styles.grid}>
      {data.map(item =>
        <div className={styles.item} key={item.title}>
          <Project project={item} />
        </div>
      )}
    </div>
  )
}
