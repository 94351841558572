import React from 'react'
import styles from './ProjectsHeader.module.scss'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'
import { ReactComponent as Arrow } from 'src/assets/images/arrow.svg'
import { Button } from '../Button/Button'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'
import { Link } from 'react-router-dom'
import { MAIN } from 'src/routes'
import { Header } from './Header'
import classNames from 'classnames'

export const ProjectsHeader = ({ isHide, modal }) => {

  const dispatch = useDispatch()

  return (
    <>
      <div className={classNames(styles.logo, isHide ? styles.hide : null)}>
        <Logo />
      </div>
      <div className={styles.header}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={classNames(styles.logo, styles.hidden)}>
              <Logo />
            </div>
          </div>
          <div className={styles.col}>
            <Link to={MAIN}>
              <div className={styles.link}>
                <div className={styles.icon}>
                  <Arrow />
                </div>
                <div className={styles.text}>Вернуться на Главную</div>
              </div>
            </Link>
          </div>
          <div className={classNames(styles.col, isHide ? styles.hide : null)}>
            <Button
              text="Обсудить проект"
              isSmall={true}
              onClick={() => dispatch(openModal(modal ? modal : 'project'))}
            />
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <Header isProject={true} />
      </div>
    </>
  )
}
