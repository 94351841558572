import React, { useEffect, useState } from 'react'
import styles from './TextAnimation.module.scss'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated, easings } from '@react-spring/web'
import classNames from 'classnames'

export const TextAnimation = ({children, delay, isStart = true}) => {

  const [isAnimated, setIsAnimated] = useState(false)

  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const [props, api] = useSpring(
    () => ({
      from: { 
        y: 210,
        opacity: 0,
        skewY: '8deg',
        scaleX: 0.9
      },
      onStart: () => {
        setIsAnimated(true)
      },
      onRest: () => {
        setIsAnimated(false)
      }
    })
  )

  // useEffect(() => {
  //   if (inView) {
  //     setTimeout(() => {
  //       api.start({
  //         to: {
  //           y: 0,
  //           opacity: 1,
  //           skewY: '0deg',
  //           scaleX: 1
  //         },
  //         config: {
  //           tension: 210,
  //           friction: 56,
  //         }
  //       })
  //     }, delay ? delay : 0);
  //   }
  // }, [inView])

  return (
    <div className={styles.wrapper} ref={ref} >
      <div 
        style={{transitionDelay: `${delay}ms`}}
        className={classNames(styles.item, inView && isStart ? styles.animated : null)}
      >
        {children}
      </div>
      {/* <animated.div 
        className={classNames(styles.item, inView ? styles.animated : null)}
      >
        {children}
      </animated.div> */}
    </div>
  )
}
