const { SET_PROJECTS } = require("./actions");


const setProjects = (data) => ({
  type: SET_PROJECTS,
  payload: data
})



export { setProjects };