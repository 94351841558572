import React, { useRef } from 'react'
import styles from './Button.module.scss'
import { ReactComponent as Plus } from 'src/assets/images/plus.svg'
import classNames from 'classnames'
import { mediaQuery } from 'src/utils/mediaQueries'

export const Button = ({ text, isSmall, isBig, icon, onClick, isChat, extraClass }) => {

  const shapeRef = useRef(null)

  const onMouseMove = event => {
    const rect = event.target.getBoundingClientRect()
    const posX = event.clientX - rect.left
    const posY = event.clientY - rect.top

    shapeRef.current.style.transform = `translate(-50%, -50%) translate(${posX}px, ${posY}px)`
  }

  const onMouseOut = () => {
    shapeRef.current.style.transform = 'none'
  }

  return (
    <button
      className={classNames(
        styles.button,
        isSmall ? styles.small : null,
        isBig ? styles.big : null,
        isChat ? styles.chat : null,
        extraClass
      )}
      onClick={onClick}
      onMouseMove={mediaQuery.matches ? onMouseMove : null}
      onMouseOut={mediaQuery.matches ? onMouseOut : null}
    >
      <div className={styles.shape} ref={shapeRef}></div>
      <div className={styles.text}>{text}</div>
      <div className={styles.icon}>
        {icon
          ?
          icon
          :
          <Plus />
        }
      </div>
    </button>
  )
}
