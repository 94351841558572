const { OPEN_MODAL, CLOSE_MODAL } = require("./actions");


const openModal = (modal) => ({
  type: OPEN_MODAL,
  payload: modal
})

const closeModal = () => ({
  type: CLOSE_MODAL,
})

export { openModal, closeModal };