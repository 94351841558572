import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { routes } from 'src/routes'

export const AppRouter = () => {

  const location = useLocation()

  return (
    <AnimatePresence mode='wait' initial={false}>
      <Routes location={location} key={location.pathname}>
        {routes.map((item, i) => 
          <Route 
            path={item.path} 
            key={item.path} 
            element={item.element} 
          />
        )}
        <Route path='*' element={<Navigate to={"/"} replace={true} />} />
      </Routes>
    </AnimatePresence>
  )
}
