import React, { useEffect } from 'react'
import { Intro } from '../Intro/Intro'
import { Cases } from '../Cases/Cases'
import { Showreel } from '../Showreel/Showreel'
import { Expertise } from '../Expertise/Expertise'
import { Services } from '../Services/Services'
import { Awards } from '../Awards/Awards'
import { Features } from '../Features/Features'
import { Chat } from '../Chat/Chat'

export const MainContent = () => {
  return (
    <div className='main'>
      <Intro />
      <Cases />
      <Showreel />
      <Expertise />
      <Services />
      <Features />
      <Awards />
      <Chat />
    </div>
  )
}
