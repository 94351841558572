import React, { useEffect, useRef, useState } from 'react'
import styles from './ShowreelModal.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import showreel from 'src/assets/videos/showreel.mp4'
import poster from 'src/assets/images/poster.webp'
import { ReactComponent as Close } from 'src/assets/images/close-cursor.svg'
import classNames from 'classnames'
import { closeModal } from 'src/store/modals/actionCreators'
import { mediaQuery } from 'src/utils/mediaQueries'

export const ShowreelModal = () => {

  const [isOpen, setIsOpen] = useState(false)
  const cursorRef = useRef(null)

  const { currentModal } = useSelector(state => state.modals)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentModal === 'showreel') {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [currentModal])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('body--hidden')
    } else {
      document.body.classList.remove('body--hidden')
    }
  }, [isOpen])

  const onMouseMove = event => {
    const posX = event.clientX
    const posY = event.clientY

    cursorRef.current.style.transform = `translate(${posX}px, ${posY}px)`
  }

  return (
    <>
      {isOpen &&
        <div
          className={classNames(styles.modal, isOpen ? styles.active : null)}
          onClick={mediaQuery.matches ? () => dispatch(closeModal()) : null}
          onMouseMove={mediaQuery.matches ? onMouseMove : null}
        >
          <div className={styles.wrapper} ref={cursorRef}>
            <div className={styles.cursor}>
              <Close />
            </div>
          </div>
          {mediaQuery.matches ?
            <video className={styles.video} autoPlay loop playsInline>
              <source src={showreel} type='video/mp4' />
            </video>
            :
            <>
              <div className={styles.frame} style={{ padding: "56.25% 0 0 0", position: "relative" }}><iframe src="https://player.vimeo.com/video/850593811?h=87b35ed2a3&autoplay=1&loop=1" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
            </>
          }
          <div
            className={styles.icon}
            onClick={mediaQuery.matches ? null : () => dispatch(closeModal())}
          >
            <Close />
          </div>
        </div>}
    </>
  )
}
