import React from 'react'
import { useDispatch } from 'react-redux';
import { updatePreloader } from 'src/store/preloader/actionCreators';

export const  Picture = ({ fileName, folder }) => {

  const dispatch = useDispatch()

  const file = {
    getTitle(filename) {
      var segments = filename.split('.');
      segments.pop();
      var title = segments.join('.');
      return title;
    },
    getExtension(filename) {
      var segments = filename.split('.');
      var extension = segments.pop();
      return extension;
    },
    getPath(title, extension) {
      var path = 'src/assets/images/' + (folder ? folder : '') + title + "." + extension;
      return path;
    },
  };
  const fileTitle = file.getTitle(fileName);
  const fileExtension = file.getExtension(fileName);
  const path = file.getPath(fileTitle, fileExtension)

  return (
    <img 
      className='img' 
      src={require(`src/assets/images/${folder ? folder : ''}${fileName}`)} 
      alt=""
      onLoad={() => dispatch(updatePreloader())}
    />
    // <picture>
    //   <source 
    //     type='image/webp' 
    //     srcSet={file.getPath(fileTitle, "webp") + " 1x, " + file.getPath(fileTitle + "_2x", "webp") + " 2x"} 
    //   />
    //   <img 
    //     src={require(file.getPath(fileTitle, fileExtension))}
    //     srcSet={require(file.getPath(fileTitle + "_2x", fileExtension) + " 2x")}
    //   />
    // </picture>
  )
}