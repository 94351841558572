import React from 'react'
import styles from './Client.module.scss'
import { Picture } from '../Picture/Picture'
import classNames from 'classnames'
import { FadeUp } from '../FadeUp/FadeUp'

export const Client = ({ src, isBig }) => {
  return (
    <div className={classNames(styles.item, isBig ? styles.big : null)}>
      <Picture folder="clients/" fileName={src} />
    </div>
  )
}
