import React, { useEffect, useState } from 'react'
import styles from './Modal.module.scss'
import { Title } from '../Title/Title'
import { ReactComponent as Cross } from 'src/assets/images/cross.svg'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from 'src/store/modals/actionCreators'
import classNames from 'classnames'
import { Reviews } from '../Reviews/Reviews'

const data = [
  {
    title: "Чистая Линия",
    src: "reviews-chl.webp",
    href: "chl.pdf"
  },
  {
    title: "ЭРГА",
    src: "reviews-erga.webp",
    href: "erga.pdf"
  },
  {
    title: "GeekBrains",
    src: "reviews-brains.webp",
    href: "geekbrains.pdf"
  },
  {
    title: "INVAR, Эпиген Интим",
    src: "reviews-epigen.webp",
    href: "invar-epigen.pdf"
  },
  {
    title: "MusicAeterna",
    src: "reviews-music.webp",
    href: "MusicAeterna.pdf"
  },
  {
    title: "Профессор Фрейд",
    src: "reviews-freud.webp",
    href: "freud.pdf"
  },
  {
    title: "Мытищинский молочный\u00A0завод",
    src: "reviews-mmz.webp",
    href: "mmz.pdf"
  },
  {
    title: "Welldone",
    src: "reviews-welldone.webp",
    href: "welldone.pdf"
  },
  {
    title: "Contentica",
    src: "reviews-contentica.webp",
    href: "contentica.jpg"
  },
]

export const ReviewsModal = () => {

  const [isOpen, setIsOpen] = useState(false)

  const { currentModal } = useSelector(state => state.modals)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentModal === 'reviews') {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [currentModal])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('body--hidden')
    } else {
      document.body.classList.remove('body--hidden')
    }
  }, [isOpen])


  return (
    <div
      className={classNames(styles.modal, isOpen ? styles.active : null)}
    >
      <div className={styles.overlay} onClick={() => dispatch(closeModal())}></div>
      <div className={classNames(styles.container)}>
        <div className={styles.wrapper}>
          <Title extraClass={styles.title}>
            Отзывы клиентов
          </Title>
          <div className={styles.reviews}>
            <Reviews data={data} />
          </div>
        </div>
        <div className={styles.icon} onClick={() => dispatch(closeModal())}>
          <Cross />
        </div>
      </div>
    </div>
  )
}
