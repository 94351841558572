import classNames from 'classnames'
import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'
import { Button } from '../Button/Button'
import { Container } from '../Container/Container'
import { Picture } from '../Picture/Picture'
import { Title } from '../Title/Title'
import styles from './Features.module.scss'
import poster from 'src/assets/images/features-bg.webp'
import video from 'src/assets/videos/features.mp4'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { FadeUp } from '../FadeUp/FadeUp'

const items = [
  ["1C-Bitrix", "Laravel", "React", "Vue", "Low-Code"],
  ["Three.js", "WebGL", "Node.js", "TypeScript", "React Native"],
  ["Rest API", "SOAP", "Webpack", "ElasticSearch", "SQL/noSQL"],
]

export const Features = () => {

  const dispatch = useDispatch()

  return (
    <div className={styles.container}>
      <div className={styles.bg}>
        <video className={styles.video} autoPlay muted loop playsInline poster={poster}>
          <source src={video} type='video/mp4' />
        </video>
      </div>
      <Container>
        <TextAnimation>
        <Title extraClass={styles.title}>Эффектно и&nbsp;эффективно занимаемся дизайном
          и&nbsp;заказной разработкой, предлагаем своих специалистов <br/> на&nbsp;outstaff</Title>
        </TextAnimation>
        <FadeUp>
          <div className={styles.desktop}>
            <Button text="Запросить Бенч" onClick={() => dispatch(openModal('bench'))} />
          </div>
        </FadeUp>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextAnimation>
                <div className={styles.label}>Форматы работы</div>
              </TextAnimation>
            </div>
            <div className={classNames(styles.col, styles.big)}>
              <ul className={classNames(styles.list, styles.big)}>
                <li className={styles.item}>
                  <TextAnimation>
                    Fix priced
                  </TextAnimation>
                </li>
                <li className={styles.item}>
                  <TextAnimation>
                    T&M
                  </TextAnimation>
                </li>
                <li className={styles.item}>
                  <TextAnimation>
                    Outstaff
                  </TextAnimation>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.desktop}>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextAnimation>
                <div className={styles.label}>Технологии в разработке</div>
              </TextAnimation>
            </div>
            {items.map((col, i) => 
              <div className={classNames(styles.col, i === 2 ? styles.big : null)} key={i}>
                <ul className={styles.list}>
                  {col.map(item =>
                    <li className={styles.item} key={item}>
                      <TextAnimation>
                        {item}
                      </TextAnimation>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={styles.mobile}>
          <div className={styles.inner}>
            <TextAnimation>
              <div className={classNames(styles.label, styles.alt)}>Технологии в разработке</div>
            </TextAnimation>
            <div className={styles.row}>
              <div className={styles.col}>
                <ul>
                  {['1C-Bitrix', 'Laravel', 'React', 'Vue', 'Low-Code', 'Rest API', 'SOAP', 'Webpack'].map(item => 
                    <li className={styles.item} key={item}>
                      <TextAnimation>
                        {item}
                      </TextAnimation>
                    </li>
                  )}
                </ul>
              </div>
              <div className={styles.col}>
                <ul>
                  {['ElasticSearch', 'SQL/noSQL', 'Three.js', 'WebGL', 'Node.js', 'TypeScript', 'React Native',].map(item => 
                    <li className={styles.item} key={item}>
                      <TextAnimation>
                        {item}
                      </TextAnimation>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div> 
          <FadeUp>
            <Button text="Запросить Бенч" onClick={() => dispatch(openModal('bench'))} />
          </FadeUp>
        </div>
      </Container>
    </div>
  )
}
