import React from 'react'
import styles from './Intro.module.scss'
import poster from 'src/assets/images/intro.webp'
import video from 'src/assets/videos/intro.mp4'
import { About } from '../About/About'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

export const Intro = () => {

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <div ref={ref} className={styles.intro}>
      <div className={styles.container}>
        <div 
          className={classNames(styles.frame, inView ? styles.active : null)}
        ></div>
        <video 
          className={classNames(styles.video, inView ? styles.active : null)} 
          autoPlay 
          muted 
          loop 
          playsInline 
          poster={poster}
        >
          <source src={video} type='video/mp4' />
        </video>
      </div>
      <About />
    </div>
  )
}
