import React, { useEffect, useMemo, useState } from 'react'
import styles from './Listing.module.scss'
import { Filters } from '../Filters/Filters'
import axios from 'axios'
import { ProjectsList } from '../ProjectsList/ProjectsList'
import { Container } from '../Container/Container'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import { TextAnimation } from '../TextAnimation/TextAnimation'

const filtersData = [
  {
    text: 'Все кейсы',
    count: 24,
    translate: 'all',
  },
  {
    text: 'Retail',
    count: 17,
    translate: 'retail',
  },
  {
    text: 'E-commerce',
    count: 10,
    translate: 'commerce',
  },
  {
    text: 'FMCG',
    count: 11,
    translate: 'fmcg',
  },
  {
    text: 'Промышленность',
    count: 8,
    translate: 'industry',
  },
  {
    text: 'Финансы',
    count: 1,
    translate: 'finance',
  },
  {
    text: 'Телеком',
    count: 1,
    translate: 'tv',
  },
  {
    text: 'Фарма',
    count: 1,
    translate: 'pharma',
  },
  {
    text: 'IT',
    count: 1,
    translate: 'it',
  },
]

export const Listing = () => {

  const [selected, setSelected] = useState('Все кейсы');
  const [data, setData] = useState([])
  const { search } = useLocation()

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (search) {
      const query = qs.parse(search, { ignoreQueryPrefix: true })
      const current = [...filtersData].find(item => item.translate === query.filter)
      
      setSelected(current.text)
    } else {
      setSelected('Все кейсы')
    }
  }, [search])

  async function fetchData() {
    try {
      const response = await axios.get("/projects.json")

      setData(response.data.projects)
    } catch (e) {
      console.log(e)
    }
  }

  const filteredData = useMemo(() => {
    if (selected === 'Все кейсы') {
      return data
    } else {
      return [...data].filter(item => item.categories.includes(selected))
    }
  }, [selected, data])

  return (
    <div className={styles.listing} id="cases">
      <Container>
        <div className={styles.mobile}>
          <div className={styles.logo}>
            <Logo />
          </div>
        </div>
        <TextAnimation>
          <h1 className={styles.title}>Кейсы</h1>
        </TextAnimation>
        <div className={styles.inner}>
          <Filters 
            // data={filtersData}
            selected={selected}
          />
        </div>
        <div className={styles.inner}>
          <ProjectsList data={filteredData} />
        </div>
      </Container>
    </div>
  )
}
