import React from 'react'
import styles from './Category.module.scss'
import classNames from 'classnames'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { FadeUp } from '../FadeUp/FadeUp'

export const Category = ({ num, text, isHorizontal, isLight }) => {
  return (
    <div 
      className={classNames(
        styles.category, 
        isHorizontal ? styles.horizontal : null,
        isLight ? styles.light : null
      )}
    >
      <FadeUp>
        <div className={styles.num}>{num}</div>
      </FadeUp>
      <TextAnimation>
        <div className={styles.text}>{text}</div>
      </TextAnimation>
    </div>
  )
}
