import React, { useEffect, useRef, useState } from 'react'
import styles from './Message.module.scss'
import { ReactComponent as Play } from 'src/assets/images/chat-play.svg'
import classNames from 'classnames'

export const Video = () => {

  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const ref = useRef(null)

  const startVideo = () => {
    setIsPlaying(true)
    ref.current.play()
  }

  const stopVideo = () => {
    if (isPlaying) {
      setIsPlaying(false)
      ref.current.pause()
    }
  }

  const onPlaying = () => {
    const time = Math.round(ref.current.currentTime)

    setCurrentTime(time)
  }

  const onEnded = () => {
    ref.current.currentTime = 0
    setIsPlaying(false)
    setCurrentTime(0)
  }

  const getProgress = () => {
    const timeLeft = duration - currentTime
    const minutes = Math.floor(timeLeft / 60)

    if (minutes > 1) {
      const secondsLeft = timeLeft % 60
      const result = secondsLeft > 10 ? String(secondsLeft) : `0${secondsLeft}`

      return `${minutes}:${result}`
    } else {
      return timeLeft > 10 ? `0:${timeLeft}` : `0:0${timeLeft}`
    }
  }

  useEffect(() => {
    ref.current.onloadedmetadata = function () {
      setDuration(Math.round(this.duration))
    }
  }, [])

  return (
    <div className={styles.video}>
      <video
        className={styles.player}
        ref={ref}
        onClick={stopVideo}
        preload='metadata'
        onTimeUpdate={onPlaying}
        onEnded={onEnded}
      >
        <source src={require(`src/assets/videos/intro.mp4`)} type='video/mp4'></source>
      </video>
      <div
        className={classNames(styles.button, isPlaying ? styles.hidden : null)}
        onClick={startVideo}
      >
        <Play />
      </div>
      <div className={styles.timer}>{getProgress()}</div>
    </div>
  )
}
