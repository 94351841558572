import { combineReducers, createStore } from 'redux';
import modalsReducer from './modals/reducer';
import preloaderReducer from './preloader/reducer';
import projectsReducer from './projects/reducer';

const rootReducer = combineReducers({
  modals: modalsReducer,
  preloader: preloaderReducer,
  projects: projectsReducer,
})


const store = createStore(rootReducer);

export { store };
