import React from 'react'
import styles from './Decor.module.scss'
import classNames from 'classnames'

export const Decor = ({isLight}) => {
  return (
    <div className={classNames(styles.decor, isLight ? styles.light : null)}>
      <div className={styles.item}></div>
      <div className={styles.item}></div>
      <div className={styles.item}></div>
      <div className={styles.item}></div>
    </div>
  )
}
