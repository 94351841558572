import React, { useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { ReactComponent as Cross } from 'src/assets/images/cross.svg'
import { ReactComponent as Tg } from 'src/assets/images/tg-header.svg'
import { ReactComponent as WhatsApp } from 'src/assets/images/wap.svg'
import { ReactComponent as Be } from 'src/assets/images/be-header.svg'
import { ReactComponent as Awards } from 'src/assets/images/awa.svg'
import { ReactComponent as Vimeo } from 'src/assets/images/vim.svg'
import { Button } from '../Button/Button'
import classNames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'
import { mediaQuery } from 'src/utils/mediaQueries'
import { Link } from 'react-router-dom'

const socials = [
  {
    icon: <Tg />,
    href: "https://t.me/afcloudmill"
  },
  {
    icon: <WhatsApp />,
    href: "https://wa.me/79313167040"
  },
  {
    icon: <Be />,
    href: "https://www.behance.net/cloudmill"
  },
  {
    icon: <Awards />,
    href: "https://www.awwwards.com/cloudmill/"
  },
  {
    icon: <Vimeo />,
    href: "https://vimeo.com/user101993758"
  },
]

const nav = [
  {
    text: "Кейсы",
    href: "/projects",
    type: "link"
  },
  {
    text: "Экспертиза",
    href: "#expertise"
  },
  {
    text: "Услуги",
    href: "#services"
  },
  {
    text: "Награды",
    href: "#awards"
  },
  {
    text: "Контакты",
    href: "#contacts"
  },
]

const projectsNav = [
  {
    text: "Главная",
    href: "/",
    type: "link"
  },
  {
    text: "Экспертиза",
    href: "/#expertise",
    type: "link"
  },
  {
    text: "Услуги",
    href: "/#services",
    type: "link"
  },
  {
    text: "Награды",
    href: "/#awards",
    type: "link"
  },
  {
    text: "Контакты",
    href: "/#contacts",
    type: "link"
  },
]

let offset;

export const Header = ({ isProject }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const dispatch = useDispatch()

  const items = isProject ? projectsNav : nav

  useEffect(() => {
    if (window.innerWidth < 1280) {
      offset = '25'
    } else {
      offset = '150'
    }

    setIsLoaded(true)
  }, [])

  return (
    <header
      className={classNames(
        styles.header,
        isOpen ? styles.active : null,
        isLoaded ? styles.loaded : null
      )}
    >
      <div className={classNames(styles.container, isOpen ? styles.active : null)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.list}>
              {socials.map((item, i) =>
                <div className={styles.item} key={i}>
                  <a href={item.href} target='_blank'>
                    {item.icon}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className={styles.col}>
            <div className={classNames(styles.list, styles.block)}>
              {items.map(item =>
                item.type === 'link'
                  ?
                  <Link to={item.href} key={item.href} className={styles.link}>
                    {item.text}
                  </Link>
                  :
                  <AnchorLink
                    offset={offset}
                    href={item.href}
                    className={styles.link}
                    key={item.href}
                    onClick={mediaQuery.matches ? null : () => { setIsOpen(false) }}
                  >
                    {item.text}
                  </AnchorLink>
              )}
            </div>
          </div>
          <div className={styles.col}>
            <Button
              text="Обсудить проект"
              isSmall={true}
              onClick={() => dispatch(openModal('project'))}
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.menu, isOpen ? styles.active : null)} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.icon}>
          <Cross />
        </div>
        <div className={styles.inner}>
          <div className={styles.text}>Меню</div>
          <div className={styles.burger}></div>
        </div>
      </div>
    </header>
  )
}
