const { UPDATE_PRELOADER, SET_LOADED } = require("./actions")

const initialState = {
  totalCount: 35,
  loaded: 0,
  isLoaded: true,
}

const preloaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRELOADER:
      return { ...state, loaded: state.loaded + 1 }
    case SET_LOADED:
      return { ...state, isLoaded: action.payload }
    default: 
      return state;
  }
}

export default preloaderReducer