import React, { useEffect } from 'react'
import { Category } from '../Category/Category'
import { Container } from '../Container/Container'
import { Picture } from '../Picture/Picture'
import styles from './Awards.module.scss'
import { ReactComponent as Award1 } from 'src/assets/images/awards-1.svg'
import { ReactComponent as Award2 } from 'src/assets/images/awards-2.svg'
import { ReactComponent as Award3 } from 'src/assets/images/awards-3.svg'
import { ReactComponent as Award4 } from 'src/assets/images/awards-4.svg'
import { ReactComponent as Award5 } from 'src/assets/images/awards-5.svg'
import { ReactComponent as Award6 } from 'src/assets/images/awards-6.svg'
import { ReactComponent as Runet } from 'src/assets/images/runet.svg'
import { Decor } from '../Decor/Decor'
import classNames from 'classnames'
import { Parallax, Background } from 'react-parallax';
import { Counter } from '../Counter/Counter'
import { Section } from '../Section/Section'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { mediaQuery } from 'src/utils/mediaQueries'
import { FadeUp } from '../FadeUp/FadeUp'

const items = [
  {
    icon: <Award1 />,
    name: "Рейтинг Рунета",
    num: 8,
  },
  {
    icon: <Award2 />,
    name: "Золотой сайт",
    num: 11,
  },
  {
    icon: <Award3 />,
    name: "Tagline Awards",
    num: 26,
  },
  {
    icon: <Award4 />,
    name: "AWWWARDS",
    num: 4,
  },
  {
    icon: <Award5 />,
    name: "CSS Design Awards",
    num: 7,
  },
  {
    icon: <Award6 />,
    name: "Behance",
    num: 6,
  },
]

const raiting = [
  {
    num: 2,
    text: 'в рейтинге разработчиков сайтов в Санкт-Петербурге'
  },
  {
    num: 2,
    text: 'в рейтинге креативности в\u00A0Санкт-Петербурге'
  },
  {
    num: 2,
    text: 'в рейтинге разработчиков сайтов питания'
  },
  {
    num: 3,
    text: 'в рейтинге разработчиков  сайтов сервисной тематики'
  },
  {
    num: 3,
    text: 'в рейтинге дизайна корпоративных сайтов'
  },
  {
    num: 4,
    text: 'в рейтинге разработчиков сайтов потребительских товаров'
  },
]

export const Awards = () => {

  // useEffect(() => {
  //   window.addEventListener("keydown", (event) => {
  //     if (event.code === "KeyZ") {
  //       debugger;
  //     }
  //   });
  // })

  return (
    <div className={styles.awards} id='awards'>
      <div className={classNames(styles.img, styles.top)}>
        <Parallax strength={mediaQuery.matches ? 375 : 150} className={classNames(styles.pic, styles.top)}>
          <Background className={classNames(styles.pic, styles.top)}>
            <Picture fileName="awards-2.webp" />
          </Background>
        </Parallax>
      </div>
      <div className={classNames(styles.img, styles.bot)}>
        <Parallax strength={mediaQuery.matches ? 375 : -100} className={classNames(styles.pic, styles.bot)}>
          <Background className={classNames(styles.pic, styles.bot)}>
            <Picture fileName="awards-1.webp" />
          </Background>
        </Parallax>
      </div>
      <div className={classNames(styles.img, styles.right)}>
        <Parallax strength={-375} className={classNames(styles.pic, styles.right)}>
          <Background className={classNames(styles.pic, styles.right)}>
            <Picture fileName="awards-2.webp" />
          </Background>
        </Parallax>
      </div>
      <Container>
        <div className={styles.container}>
          <Category text="Достижения и награды" num="4" isLight={true} />
          <div className={styles.inner}>
            <div className={styles.point}>
              <Counter
                num={22}
                extraClass={classNames(styles.count, styles.blue)}
                plus={true}
              />
              <TextAnimation>
                <div className={styles.text}>
                  <span>место</span> в рейтинге разработчиков сайтов
                </div>
              </TextAnimation>
            </div>
            <div className={styles.point}>
              {/* <TextAnimation>
                <div className={classNames(styles.count, styles.green)}>8</div>
              </TextAnimation> */}
              <Counter
                num={8}
                extraClass={classNames(styles.count, styles.green)}
                plus={true}
              />
              <TextAnimation>
                <div className={styles.text}>
                  <span>место</span> в рейтинге креативности
                </div>
              </TextAnimation>
            </div>
          </div>
          <div className={styles.grid}>
            {raiting.map(item =>
              <div className={classNames(styles.item, styles.alt)} key={item.text}>
                <FadeUp extraClass={styles.animation}>
                  <Decor />
                </FadeUp>
                <TextAnimation>
                  <div className={styles.number}>
                    {item.num}
                    <div className={styles.place}>место</div>
                  </div>
                </TextAnimation>
                <TextAnimation>
                  <div className={styles.desc}>{item.text}</div>
                </TextAnimation>
              </div>
            )}
          </div>
          <div className={styles.bot}>
            <div className={styles.runet}>
              <FadeUp>
                <Runet />
              </FadeUp>
            </div>
            <TextAnimation>
              <div className={classNames(styles.desc, styles.alt)}>По данным Рейтинга Рунета 2023</div>
            </TextAnimation>
          </div>
        </div>
        <Section>
          <div className={styles.wrapper}>
            <Counter num={60} extraClass={styles.count} />
            <TextAnimation>
              <div className={styles.caption}>Наград на отечественных <br /> и международных конкурсах</div>
            </TextAnimation>
          </div>
          <div className={styles.grid}>
            {items.map(item =>
              <div className={styles.item} key={item.name}>
                <FadeUp extraClass={styles.animation}>
                  <Decor />
                </FadeUp>
                <FadeUp>
                  <div className={styles.icon}>{item.icon}</div>
                </FadeUp>
                <TextAnimation>
                  <div className={styles.name}>{item.name}</div>
                </TextAnimation>
                <TextAnimation>
                  <div className={styles.num}>
                    <span>x</span>
                    {item.num}
                  </div>
                </TextAnimation>
              </div>
            )}
          </div>
        </Section>
      </Container>
    </div>
  )
}
