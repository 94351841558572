import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Footer } from 'src/components/Footer/Footer'
import { Header } from 'src/components/Header/Header'
import { MainContent } from 'src/components/MainContent/MainContent'
import { BenchModal } from 'src/components/Modal/BenchModal'
import { ProjectModal } from 'src/components/Modal/ProjectModal'
import { ShowreelModal } from 'src/components/ShowreelModal/ShowreelModal'
import { motion } from "framer-motion";
import { ReviewsModal } from 'src/components/Modal/ReviewsModal'

export const Main = () => {

  const { pathname, hash } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const section = document.querySelector(hash)
        const offset = section.getBoundingClientRect().top
  
        window.scrollTo(0, offset - 100)
      }, 200);
    }
  }, [])

  return (
    <>
      <motion.div
        className='page-overlay'
        initial={{ x: '0%', transition: { duration: 0.5 } }}
        animate={{ x: '100%', transition: { duration: 0.5 } }}
        exit={{ x: '100%', transition: { duration: 0.5 } }}
      />
      <motion.div
        className='page-overlay'
        initial={{ x: '-100%', transition: { duration: 0.5 } }}
        animate={{ x: '-100%', transition: { duration: 0.5 } }}
        exit={{ x: '0%', transition: { duration: 0.5 } }}
      />
      <Header />
      <MainContent />
      <Footer />
      <ProjectModal />
      <BenchModal />
      <ReviewsModal />
      <ShowreelModal />
    </>
  )
}
