import React from 'react'
import styles from './Message.module.scss'
import { ReactComponent as Check } from 'src/assets/images/check-message.svg'
import classNames from 'classnames'
import moment from 'moment'
import { Video } from './Video'

export const Message = ({ message, isVideo, isUser, currentMessage }) => {

  const date = moment()

  return (
    <div className={styles.item}>
      {isUser ?
        <div className={classNames(styles.container, styles.user)}>
          <div className={styles.wrapper}>
            <div className={styles.message}>{message}</div>
          </div>
          <div className={styles.info}>
            <div className={styles.text}>{`${date.hour()}:${date.minute()}`}</div>
            <div className={styles.icon}>
              <Check />
            </div>
          </div>
        </div>
        :
        <div 
          className={classNames(
            styles.container, 
            currentMessage === message ? styles.active : null
          )}
        >
          {isVideo &&
            <Video />}
          <div className={styles.wrapper}>
            <div className={styles.message}>{message}</div>
          </div>
          <div className={styles.info}>
            <div className={styles.text}>{`${date.hour()}:${date.minute()}`} Надя</div>
          </div>
        </div>
      }
    </div>
  )
}
