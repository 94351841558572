import React from 'react'
import styles from './File.module.scss'
import { ReactComponent as Clip } from 'src/assets/images/clip.svg'

export const File = ({ onChange }) => {

  return (
    <label className={styles.label}>
      <input
        className={styles.input}
        type="file"
        onChange={onChange}
        multiple
      />
      <div className={styles.icon}>
        <Clip />
      </div>
      <div className={styles.text}>Прикрепить файл</div>
    </label>
  )
}
