import { useSpring, animated } from '@react-spring/web'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './FadeUp.module.scss'

export const FadeUp = ({ children, extraClass, delay, isStart = true }) => {

  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  const [props, api] = useSpring(
    () => ({
      from: {
        y: 100,
        opacity: 0,
      },
    })
  )

  // useEffect(() => {
  //   if (inView) {
  //     setTimeout(() => {
  //       api.start({
  //         from: {},
  //         to: {
  //           y: 0,
  //           opacity: 1,
  //         },
  //       })
  //     }, delay ? delay : 0,);
  //   }
  // }, [inView])

  return (
    <div ref={ref} className={extraClass}>
      {/* <animated.div
        // style={props}
        styles={{'transition-delay': `${delay}ms`}}
        className={classNames(styles.div, inView ? styles.animated : null)}
      >
        {children}
      </animated.div> */}
      <div
        // style={props}
        style={{transitionDelay: `${delay}ms`}}
        className={classNames(styles.div, inView && isStart ? styles.animated : null)}
      >
        {children}
      </div>
    </div>
  )
}
