import React, { useEffect, useState } from 'react'
import styles from './Counter.module.scss'
import { Title } from '../Title/Title'
import { useInView } from 'react-intersection-observer'
import Odometer from 'react-odometerjs';
import classNames from 'classnames';
import { TextAnimation } from '../TextAnimation/TextAnimation';

export const Counter = ({ num, text, extraClass, plus }) => {

  const [value, setValue] = useState(0)

  const {ref, inView} = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      setValue(num)
    }
  }, [inView])
  
  return (
    <div className={styles.counter} ref={ref}>
      <Title extraClass={classNames(styles.num, extraClass)}>
        <Odometer duration={1000} value={value} />
        {!plus && <>+</>}
        {/* + */}
      </Title>
      {text && 
        <TextAnimation>
          <div className={styles.text}>{text}</div>
        </TextAnimation>}
    </div>
  )
}
