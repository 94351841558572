const { UPDATE_PRELOADER, SET_LOADED } = require("./actions");


const updatePreloader = () => ({
  type: UPDATE_PRELOADER,
})

const setLoaded = (isLoaded) => ({
  type: SET_LOADED,
  payload: isLoaded
})

export { updatePreloader, setLoaded };