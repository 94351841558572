import React from 'react'
import styles from './CategoryItem.module.scss'
import classNames from 'classnames'

export const CategoryItem = ({ item }) => {

  return (
    <div className={classNames(styles.item, item.icon ? styles.alt : null)}>
      {item.icon &&
        <span className={classNames(styles.icon, styles[item.icon])}>
        </span>}
      {item.name}
      {item.count &&
        <span className={styles.span}> ×{item.count}
        </span>}
    </div>
  )
}
