import React, { useEffect, useRef, useState } from 'react'
import styles from './Showreel.module.scss'
import { Section } from '../Section/Section'
import { Container } from '../Container/Container'
import { ReactComponent as Play } from 'src/assets/images/play.svg'
import { ReactComponent as Decor } from 'src/assets/images/decor.svg'
import { Picture } from '../Picture/Picture'
import { useScroll } from 'framer-motion'
import { Background, Parallax } from 'react-parallax'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'
import { mediaQuery } from 'src/utils/mediaQueries'
import video from 'src/assets/videos/show-preview.mp4'
import { FadeUp } from '../FadeUp/FadeUp'


export const Showreel = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [scaleProgress, setScaleProgress] = useState(0)
  const targetRef = useRef(null)
  const cursorRef = useRef(null)

  const dispatch = useDispatch()

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })

  useEffect(() => {
    scrollYProgress.onChange(res => {
      setScaleProgress(res)
    })

    if (mediaQuery.matches) {
      window.addEventListener('mousemove', onMouseMove)
    }

    return () => {
      if (mediaQuery.matches) {
        window.removeEventListener('mousemove', onMouseMove)
      }
    }
  }, [])

  const onMouseMove = event => {
    const posX = event.clientX 
    const posY = event.clientY 

    cursorRef.current.style.transform = `translate(${posX}px, ${posY}px)`
  }
  // const onMouseMove = event => {
  //   const posX = event.clientX - event.target.getBoundingClientRect().left
  //   const posY = event.clientY - event.target.getBoundingClientRect().top

  //   cursorRef.current.style.transform = `translate(${posX}px, ${posY}px)`
  // }

  const onMouseOver = () => {
    setIsExpanded(true)
  }

  const onMouseOut = () => {
    setIsExpanded(false)
  }

  return (
    <Section>
      <Container>
        <div
          className={styles.parent}
          // onMouseMove={mediaQuery.matches ? onMouseMove : null}
          onMouseOver={mediaQuery.matches ? onMouseOver : null}
          onMouseOut={mediaQuery.matches ? onMouseOut : null}
          onClick={mediaQuery.matches ? () => dispatch(openModal('showreel')) : null}
        >
          <FadeUp>
            <div className={styles.container} ref={targetRef}>
              <Parallax strength={mediaQuery.matches ? 300 : 100} className={styles.parallax}>
                <Background>
                  <div
                    className={styles.img}
                    style={mediaQuery.matches ? { transform: `scale(${1.15 - (0.15 * scaleProgress)})` } : {}}
                  >
                    <video className={styles.video} autoPlay muted loop playsInline>
                      <source src={video} type='video/mp4' />
                    </video>
                  </div>
                </Background>
              </Parallax>
            </div>
          </FadeUp>
          <div className={styles.wrapper} ref={cursorRef}>
            <div
              className={classNames(styles.cursor, isExpanded ? styles.active : null)}
              onClick={mediaQuery.matches ? null : () => dispatch(openModal('showreel'))}
            >
              <div className={styles.decor}>
                <Decor />
              </div>
              <div className={styles.decor}>
                <Decor />
              </div>
              <div className={styles.decor}>
                <Decor />
              </div>
              <div className={styles.decor}>
                <Decor />
              </div>
              <div className={styles.inner}>
                <div className={styles.text}>Смотреть <br /> Showreel</div>
                <div className={styles.icon}>
                  <Play />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}
