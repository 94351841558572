import React, { useEffect, useRef } from 'react'
import styles from './Filters.module.scss'
import classNames from 'classnames'
import { mediaQuery } from 'src/utils/mediaQueries'

export const FiltersItem = ({item, onChange, selected, listRef}) => {

  const ref = useRef(null)

  useEffect(() => {
    if (!mediaQuery.matches) {
      if (selected === item.text) {
        listRef.current.scrollLeft = ref.current.offsetLeft
      }
    }
  }, [])

  return (
    <div
      ref={ref}
      className={classNames(
        styles.item,
        selected === item.text ? styles.active : null
      )}
      onClick={() => onChange(item.translate)}
    >
      <div className={styles.text}>{item.text}</div>
      <div className={styles.count}>{item.num}</div>
    </div>
  )
}
