import React, { useEffect } from 'react'
import { Container } from '../Container/Container'
import styles from './Footer.module.scss'
import { ReactComponent as Tg } from 'src/assets/images/tg.svg'
import { ReactComponent as WhatsApp } from 'src/assets/images/whatsapp.svg'
import { ReactComponent as Be } from 'src/assets/images/be.svg'
import { ReactComponent as Awards } from 'src/assets/images/awards.svg'
import { ReactComponent as Vimeo } from 'src/assets/images/vimeo.svg'
import { ReactComponent as Pdf } from 'src/assets/images/pdf.svg'
import { ReactComponent as Up } from 'src/assets/images/up.svg'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'
import { ReactComponent as Accred } from 'src/assets/images/accreditation.svg'
import { ReactComponent as Bitrix } from 'src/assets/images/bitrix.svg'
import classNames from 'classnames'
import { Button } from '../Button/Button'
import { useDispatch } from 'react-redux'
import { openModal } from 'src/store/modals/actionCreators'
import { Background, Parallax } from 'react-parallax'
import { mediaQuery } from 'src/utils/mediaQueries'
import { Link, useLocation } from 'react-router-dom'
import { BITRIX } from 'src/routes'

export const Footer = ({ node }) => {

  const first = [
    {
      icon: <Be />,
      text: 'Behance',
      href: 'https://www.behance.net/cloudmill'
    },
    {
      icon: <Awards />,
      text: 'AWWWARDS',
      href: 'https://www.awwwards.com/cloudmill/'
    },
    {
      icon: <Vimeo />,
      text: 'Vimeo',
      href: 'https://vimeo.com/user101993758'
    },
  ]

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const dispatch = useDispatch()

  return (
    <>
      {mediaQuery.matches ?
        <Parallax strength={200} className={styles.parent}>
          <Background>
            <footer className={styles.footer} id='contacts' ref={node}>
              <div className={styles.desktop}>
                <Container>
                  <div className={styles.top}>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.contacts}>
                          <div className={styles.wrapper}>
                            <a className={styles.contact} href="mailto:info@cloudmill.ru">info@cloudmill.ru</a>
                          </div>
                          <div className={styles.wrapper}>
                            <a className={styles.contact} href="tel:88124256717">8 812 425-67-17</a>
                          </div>
                        </div>
                        <div className={styles.addresses}>
                          <div className={styles.address}>
                            <div className={styles.heading}>Санкт-Петербург</div>
                            <div className={styles.label}>192029, пр-кт Обуховской Обороны 86 литер К, помещение 19Н, офис 202</div>
                          </div>
                          <div className={styles.address}>
                            <div className={styles.heading}>Воронеж</div>
                            <div className={styles.label}>163192, Бахметьева д.20, оф. 218</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.col}>
                        <div className={styles.inner}>
                          <div className={styles.content}>
                            <div className={styles.links}>
                              <a className={styles.link} href="https://t.me/afcloudmill" target="_blank" rel="noreferrer">
                                <div className={styles.icon}>
                                  <Tg />
                                </div>
                                <div className={styles.text}>Telegram</div>
                              </a>
                              <a className={styles.link} href="https://wa.me/79313167040" target="_blank" rel="noreferrer">
                                <div className={styles.icon}>
                                  <WhatsApp />
                                </div>
                                <div className={styles.text}>WhatsApp</div>
                              </a>
                            </div>
                            <a className={classNames(styles.text, styles.alt)} href="https://docs.google.com/forms/d/1QUiF8vW4EVNgnRdb77thBDUVoQpTXNgS0cbMBwDmZB8/viewform?edit_requested=true" target="_blank" rel="noreferrer">Заполнить бриф</a>
                          </div>
                          <div className={styles.btn}>
                            <Button text='Обсудить проект' onClick={() => dispatch(openModal('project'))} />
                          </div>
                        </div>
                        <div className={styles.grid}>
                          <div className={styles.column}>
                            {first.map(item =>
                              <div className={styles.item} key={item.text}>
                                <a className={styles.ref} href={item.href} target="_blank" rel="noreferrer">
                                  <div className={styles.img}>
                                    {item.icon}
                                  </div>
                                  <div className={styles.caption}>{item.text}</div>
                                </a>
                              </div>
                            )}
                          </div>
                          <div className={styles.column}>
                            <div className={styles.item}>
                              <a className={styles.ref} href="https://www.cloudmill.ru/iacm.pdf" target="_blank" rel="noreferrer">
                                <div className={styles.img}>
                                  <Pdf />
                                </div>
                                <div className={styles.caption}>Презентация</div>
                              </a>
                            </div>
                          </div>
                          <div className={classNames(styles.column, styles.bottom)}>
                            <div className={styles.item}>
                              <div className={styles.ref} onClick={scrollUp}>
                                <div className={styles.img}>
                                  <Up />
                                </div>
                                <div className={styles.caption}>Наверх</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.bot}>
                    <div className={styles.row}>
                      <div className={styles.col}>
                        <div className={styles.bitrix}>
                          <Link className={styles.ref} to={BITRIX}>
                            <div className={styles.img}>
                              <Bitrix />
                            </div>
                            <div className={styles.caption}>Битрикс24</div>
                          </Link>
                        </div>
                      </div>
                      <div className={classNames(styles.col, styles.alt)}>
                        <div className={styles.politics}>
                          <div className={styles.container}>
                            <a className={styles.policy} href="/privacy-policy.pdf" target="_blank" rel="noreferrer">Политика конфиденциальности</a>
                          </div>
                          <div className={styles.made}>© 2024 CloudMill Digital Production</div>
                        </div>
                        <div className={styles.it}>
                          <div className={styles.svg}>
                            <Accred />
                          </div>
                          <div className={styles.accreditation}>
                            Аккредитованная ИТ-компания
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.logo}>
                      <Logo />
                    </div>
                  </div>
                </Container>
              </div>
            </footer>
          </Background>
        </Parallax>
        :
        <footer className={styles.footer} id='contacts' ref={node}>
          <div className={styles.mobile}>
            <Container>
              <div className={styles.top}>
                <div className={styles.contacts}>
                  <div className={styles.wrapper}>
                    <a className={styles.contact} href="mailto:info@cloudmill.ru">info@cloudmill.ru</a>
                  </div>
                  <div className={styles.wrapper}>
                    <a className={styles.contact} href="tel:88124256717">8 812 425-67-17</a>
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.links}>
                    <a className={styles.link} href="https://t.me/afcloudmill" target="_blank" rel="noreferrer">
                      <div className={styles.icon}>
                        <Tg />
                      </div>
                      <div className={styles.text}>Telegram</div>
                    </a>
                    <a className={styles.link} href="https://wa.me/79313167040" target="_blank" rel="noreferrer">
                      <div className={styles.icon}>
                        <WhatsApp />
                      </div>
                      <div className={styles.text}>WhatsApp</div>
                    </a>
                  </div>
                  <a className={styles.text} href="https://docs.google.com/forms/d/1QUiF8vW4EVNgnRdb77thBDUVoQpTXNgS0cbMBwDmZB8/viewform?edit_requested=true" target="_blank" rel="noreferrer">Заполнить бриф</a>
                </div>
                <div className={styles.grid}>
                  <div className={styles.column}>
                    {first.map(item =>
                      <div className={styles.item} key={item.text}>
                        <a className={styles.ref} href={item.href} target="_blank" rel="noreferrer">
                          <div className={styles.img}>
                            {item.icon}
                          </div>
                          <div className={styles.caption}>{item.text}</div>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className={styles.column}>
                    <div className={styles.item}>
                      <a className={styles.ref} href="https://www.cloudmill.ru/iacm.pdf" target="_blank" rel="noreferrer">
                        <div className={styles.img}>
                          <Pdf />
                        </div>
                        <div className={styles.caption}>Презентация</div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.addresses}>
                  <div className={styles.address}>
                    <div className={styles.heading}>Санкт-Петербург</div>
                    <div className={styles.label}>192029, пр-кт Обуховской Обороны 86 литер К, помещение 19Н, офис 202</div>
                  </div>
                  <div className={styles.address}>
                    <div className={styles.heading}>Воронеж</div>
                    <div className={styles.label}>163192, Бахметьева д.20, оф. 218</div>
                  </div>
                </div>
              </div>
              <div className={styles.bot}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div className={styles.politics}>
                      <a className={styles.policy} href="/privacy-policy.pdf" target="_blank" rel="noreferrer">Политика конфиденциальности</a>
                      <div className={styles.made}>© 2024 CloudMill Digital Production</div>
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div className={classNames(styles.grid, styles.default)}>
                      <div className={styles.item}>
                        <div className={styles.ref} onClick={scrollUp}>
                          <div className={styles.img}>
                            <Up />
                          </div>
                          <div className={styles.caption}>Наверх</div>
                        </div>
                      </div>
                      <Link className={styles.ref} to={BITRIX}>
                        <div className={styles.img}>
                          <Bitrix />
                        </div>
                        <div className={styles.caption}>Битрикс24</div>
                      </Link>
                    </div>
                    <div className={styles.it}>
                      <div className={styles.svg}>
                        <Accred />
                      </div>
                      <div className={styles.accreditation}>
                        Аккредитованная ИТ-компания
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.logo}>
                  <Logo />
                </div>
              </div>
            </Container>
          </div>
        </footer>
      }
    </>
  )
}
