import React from 'react'
import { CategoryItem } from '../CategoryItem/CategoryItem'
import styles from './CategoryList.module.scss'

export const CategoryList = ({ items, currentActive, onClick }) => {
  return (
    <div className={styles.list}>
      {items.map(item =>
        <CategoryItem
          key={item.name}
          item={item}
          currentActive={currentActive}
          onClick={onClick}
        />
      )}
    </div>
  )
}
