import 'src/styles/index.scss';
import 'src/styles/app.scss';
import 'src/styles/styles.css';
import { AppRouter } from './components/AppRouter/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProjects } from './store/projects/actionCreators';
import axios from 'axios';

function App() {
  const ref = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      const response = await axios.get("/projects.json")

      dispatch(setProjects(response.data.projects))
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='wrapper' ref={ref} >
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
