import React, { useEffect, useRef, useState } from 'react'
import styles from './Project.module.scss'
import { Picture } from '../Picture/Picture'
import { CategoryList } from '../CategoryList/CategoryList'
import classNames from 'classnames'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { FadeUp } from '../FadeUp/FadeUp'

export const Project = ({ project }) => {

  const [isActive, setIsActive] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (isActive) {
      ref.current.play()
    } else {
      ref.current.pause()
      ref.current.currentTime = 0
    }
  }, [isActive])

  return (
    <div 
      className={styles.project}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <a
        className={styles.link}
        href={project.href}
        target='_blank'
      >
        <FadeUp>
          <div className={classNames(styles.img, isActive ? styles.active : null)}>
            <Picture fileName={project.src} />
            <video muted loop playsInline className={styles.video} ref={ref} preload='none'>
              <source src={require(`src/assets/videos/${project.video}`)} type='video/mp4'></source>
            </video>
          </div>
        </FadeUp>
        <TextAnimation>
          <div className={styles.title}>{project.title}</div>
        </TextAnimation>
        <TextAnimation>
          <div className={styles.description}>{project.description}</div>
        </TextAnimation>
      </a>
      {project.awards
        ?
        <div className={styles.list}>
          <FadeUp>
            <CategoryList items={project.awards} />
          </FadeUp>
        </div>
        :
        null
      }
    </div>
  )
}
