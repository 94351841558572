import React from 'react'
import styles from './Reviews.module.scss'
import { Picture } from '../Picture/Picture'
import { ReactComponent as Ref } from 'src/assets/images/ref.svg'


export const Reviews = ({data}) => {
  return (
    <div className={styles.grid}>
      {data.map(item =>
        <div 
          className={styles.item} 
          key={item.title} 
        >
          <a 
            className={styles.link}
            href={item.href} 
            target='_blank' 
            rel="noreferrer"
          >
            <div className={styles.container}>
              <Picture fileName={item.src} />
            </div>
            <div className={styles.icon}>
              <Ref />
            </div>
          </a>
          <div className={styles.name}>{item.title}</div>
        </div>
      )}
    </div>
  )
}
