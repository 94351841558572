import React from 'react'
import { Section } from '../Section/Section'
import { Container } from '../Container/Container'
import styles from './Services.module.scss'
import { Category } from '../Category/Category'
import { ReactComponent as Service1 } from 'src/assets/images/service-1.svg'
import { ReactComponent as Service2 } from 'src/assets/images/service-2.svg'
import { ReactComponent as Service3 } from 'src/assets/images/service-3.svg'
import { ReactComponent as Service4 } from 'src/assets/images/service-4.svg'
import { ReactComponent as Service5 } from 'src/assets/images/service-5.svg'
import { ReactComponent as Service6 } from 'src/assets/images/service-6.svg'
import { ReactComponent as Service7 } from 'src/assets/images/service-7.svg'
import { Decor } from '../Decor/Decor'
import classNames from 'classnames'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { FadeUp } from '../FadeUp/FadeUp'

const items = {
  first: [
    {
      icon: <Service1 />,
      text: "UX-аналитика, интервью, CJM и прочее, что помогает в грамотной постановке задачи",
      title: "Исследования"
    },
    {
      // icon: <Service2 />,
      img: 'service-2.png',
      text: "Сервисный и коммуникационный дизайн для веб и мобайл на базе пользовательского опыта",
      title: "Дизайн"
    },
    {
      icon: <Service3 />,
      text: "Цифровые продукты: интернет-магазины, корпоративные сайты, кабинеты, сервисы, интеграции",
      title: "Веб-разработка"
    },
    {
      icon: <Service4 />,
      text: "Проектирование, дизайн и разработка нативных и кроссплатформенных приложений",
      title: "Мобайл разработка"
    },
  ],
  second: [
    {
      icon: <Service5 />,
      text: "Айдентика и масштабирование стиля в цифровой среде: развиваем, обновляем или создаем с нуля",
      title: "Брендинг"
    },
    {
      icon: <Service6 />,
      text: "Быстрый запуск ярких проектов на Tilda и Webflow в рамках ограниченных бюджетов и сроков",
      title: "No-code"
    },
    {
      icon: <Service7 />,
      text: "Фото-, видео-съемка, разработка и адаптация баннеров, 3D-графика, motion и копирайтинг",
      title: "Контент-продакшн"
    },
  ],
}

export const Services = () => {
  return (
    <Section id='services'>
      <div className={styles.container}>
        <Container>
          <div className={styles.wrapper}>
            <Category
              num="3"
              text="Наш подход к работе лежит в плоскости аналитики, ярких дизайн решений и, как итог, построении эффективной коммуникации"
              isLight={true}
            />
          </div>
          <TextAnimation>
            <h3 className={styles.title}>Услуги</h3>
          </TextAnimation>
          <Section>
            <div className={styles.row}>
              {items.first.map(item =>
                <div className={styles.item} key={item.title}>
                  <FadeUp extraClass={styles.animation}>
                    <Decor />
                  </FadeUp>
                  <FadeUp>
                    {item.icon 
                      ?
                      <div className={styles.icon}>
                        {item.icon}
                      </div>
                      :
                      <div className={styles.icon}>
                        <img src={require(`src/assets/images/${item.img}`)} alt="" />
                      </div>
                    }
                  </FadeUp>
                  <TextAnimation>
                    <div className={styles.heading}>{item.title}</div>
                  </TextAnimation>
                  <TextAnimation>
                    <div className={styles.text}>{item.text}</div>
                  </TextAnimation>
                </div>
              )}
            </div>
            <div className={classNames(styles.row, styles.center)}>
              {items.second.map(item =>
                <div className={styles.item} key={item.title}>
                  <FadeUp extraClass={styles.animation}>
                    <Decor />
                  </FadeUp>
                  {item.icon 
                    ?
                    <div className={styles.icon}>
                      {item.icon}
                    </div>
                    :
                    <div className={styles.icon}>
                      <img src={require(`src/assets/images/${item.img}`)} alt="" />
                    </div>
                  }
                  <TextAnimation>
                    <div className={styles.heading}>{item.title}</div>
                  </TextAnimation>
                  <TextAnimation>
                    <div className={styles.text}>{item.text}</div>
                  </TextAnimation>
                </div>
              )}
            </div>
          </Section>
        </Container>
      </div>
    </Section>
  )
}
